<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <!-- general tab -->
    <b-tab active>
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">Laporan</span>
      </template>

      <template>
        <b-card>
          <!-- form -->
          <b-form class="mt-2">
            <b-row>
              <b-col sm="12">
                <b-form-group label="Nama Lengkap">
                  <b-form-input
                    v-model="localData.name"
                    placeholder="Nama Lengkap"
                    name="namalengkap"
                    readonly
                  />
                </b-form-group>
              </b-col>
              <b-col sm="12">
                <b-form-group label="Tempat" label-for="tempat">
                  <b-form-input
                    v-model="localData.tempat"
                    name="tempat"
                    placeholder="Tempat"
                  />
                </b-form-group>
              </b-col>
              <b-col cols="12">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mt-2 mr-1"
                  @click="simpan"
                >
                  <b-spinner v-show="btnSimpanSpinnerShow" small type="grow" />
                  Simpan
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </template>
    </b-tab>
    <!--/ general tab -->
  </b-tabs>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BSpinner,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { api } from '@/hap-sia/setup'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BSpinner,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localData: {
        biodata_id: null,
        name: null,
        tempat: null,
        id: null,
      },
      isEdit: false,
      isAdmin: false,
      btnSimpanSpinnerShow: false,
      masterUser: null,
      isPilihDPC: false,
      masterStrukturOrganisasi: null,
    }
  },
  computed: {},
  async mounted() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    // const userBiodata = await api().get(`probono/biodatabyuser/${userData.id}`)

    const tmpUserId = this.$route.params.id
    if (tmpUserId != null) {
      try {
        this.isEdit = true
        const temp = await api().get(`probono/laporan/${tmpUserId}`)
        // this.localData.user_id = userData.id
        this.localData.name = temp.data.biodata[0].nama_lengkap
        this.localData.tempat = temp.data.tempat
        this.localData.id = temp.data.id
        // this.localData.updated_by = userBiodata.data.biodata[0].id
      } catch (error) {
        this.loadingMessage = 'Something went wrong. Please refresh browser'
      }
    } else if (this.$route.params.biodata_id) {
      // TAMBAH BY ADMIN
      this.isAdmin = true
      this.isEdit = false
      const temp = await api().get(`biodata/${this.$route.params.biodata_id}`)
      this.localData.biodata_id = temp.data.id
      this.localData.name = temp.data.nama_lengkap
      this.localData.created_by = userData.id
    } else {
      // TAMBAH MANDIRI
      const temp = await api().get(`probono/biodatabyuser/${userData.id}`)
      this.localData.biodata_id = temp.data.biodata[0].id
      this.localData.created_by = temp.data.biodata[0].id
      this.localData.name = temp.data.biodata[0].nama_lengkap
    }
  },
  methods: {
    async simpan() {
      if (!this.localData.name || !this.localData.tempat) {
        this.makeToast('danger', 'Error', 'Semua Kolom Wajib Diisi', true)
        return
      }

      this.btnSimpanSpinnerShow = true
      try {
        let result
        delete this.localData.name
        if (this.isEdit) {
          delete this.localData.biodata_id
          delete this.localData.name
          result = await api().patch('probono/laporan', this.localData)
        } else {
          delete this.localData.id
          result = await api().post('probono/laporan', this.localData)
        }
        if (result) {
          if (this.isAdmin) {
            this.$router.push('/probono/keanggotaan/laporan')
          } else {
            this.$router.push('/probono/laporan')
          }
        }
      } catch (error) {
        this.makeToast('danger', 'Error', error.response.data.message, true)
        return
      }
      this.makeToast('success', 'Sukses', 'Data berhasil ditambahkan')
    },
    makeToast(variant = null, title, body, noAutoHide = false) {
      this.$bvToast.toast(body, {
        title,
        variant,
        solid: true,
        autoHideDelay: 5000,
        noAutoHide,
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
